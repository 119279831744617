@use "sass:map";

@import "./../required";

.navigation-menu {

    .navigation-menu-items {
        display: flex;
        flex-direction: row;
        margin: 0;

        .nav-item {
            position: relative;
            display: flex;
            align-items: center;
            padding: $spacer;
            color: $secondary;
            cursor: pointer;
            background: none;
            border: none;

            &::after {
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                height: 2px;
                content: " ";
                background-color: $primary;
                opacity: 0;
                transition: $transition-fade;
            }

            &.is-active::after,
            &:hover::after {
                opacity: 1;
            }
        }

        .nav-item-content {
            position: absolute;
            right: 0;
            left: 0;
            display: none;
            padding: map.get($spacers, 4) 0;
            margin-top: 1px;
            background-color: $white;
            border-bottom: 1px solid $border-color-translucent;

            &.is-visible {
                display: block;
                transition: opacity .3s ease-in-out;
            }
        }

        @include list-unstyled();
    }

    .nav-slider {

        .item {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: map.get($spacers, 2);
            margin-right: $spacer;
            overflow: hidden;
            color: $black;
            background-color: $gray-100;
            border-radius: $border-radius;
            transition: $transition-base;

            &:hover {
                background-color: $gray-200;
            }
        }

        .image {
            width: 150px;
            height: 100px;
            margin-bottom: map.get($spacers, 2);
        }
    }

    .nav-tree {
        display: flex;
        width: 100%;

        .nav-tree-sidebar {
            display: flex;
            flex-direction: column;
            min-width: 200px;

            .sidebar-item {
                display: flex;
                flex-direction: row;
                gap: map.get($spacers, 2);
                align-items: center;
                padding: map.get($spacers, 2);
                color: $secondary;
                cursor: pointer;
                border-radius: $border-radius;

                &:hover,
                &.active {
                    background-color: $gray-100;
                }
            }

            .image {
                height: 20px;
            }

            @include list-unstyled();
        }

        .nav-tree-items-wrapper {
            position: relative;
            top: 0;
            display: grid;
            flex: 1;
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            gap: $spacer;
            align-self: flex-start;
            width: calc(100% - 200px);
            padding-left: $spacer;
            overflow-y: auto;
        }

        .menu-item {
            display: none;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            aspect-ratio: 1/1;
            padding: map.get($spacers, 2);
            color: $secondary;
            text-decoration: none;
            background-color: $gray-100;
            border-radius: $border-radius;
            transition: $transition-base;

            &:hover {
                background-color: $gray-200;
            }

            &.is-visible {
                display: flex;
            }

            .image {
                height: 50px;
                margin-bottom: $spacer;
            }

            .title {
                text-align: center;
            }
        }
    }
}
