
@import "./../scss/required";

.dropdown-cart {
    .dropdown-menu {
        padding: 0;

        &.show {
            display: none;
        }

        @include media-breakpoint-up(md) {
            min-width: 350px;

            &.show {
                display: block;
            }
        }
    }

    .cart-header-products {
        max-height: 60vh;
        overflow-x: hidden;
        overflow-y: scroll;
        font-size: $font-size-sm;
        border-bottom: 1px solid $hr-border-color;

        .lines-product {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .cart-header-c2a {
        padding: $dropdown-item-padding-x;
        margin-bottom: 0;
        background-color: $gray-100;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            + li {
                margin-top: $spacer;
            }
        }

        .btn-primary {
            margin-left: auto;
        }

        .item-delivery {
            align-items: flex-start;
            justify-content: flex-start;

            .icon {
                flex: 0 0 auto;
                margin-right: $spacer;
            }
        }
    }
}
