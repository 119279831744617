@import "./../required";

.nav :deep() {
    flex-wrap: nowrap;
    font-family: $font-family-sans-serif;

    .nav-item-links {
        display: none;
        margin-right: $spacer;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    .nav-link {
        padding: $nav-link-padding-y calc(#{$nav-link-padding-x} / 2);
        color: $black;
        white-space: nowrap;

        &.active,
        &.router-link-exact-active {
            font-weight: $font-weight-bold;
        }
    }

    [data-notification]::after {
        color: $primary-2;
        background-color: $white;
        box-shadow: none;
    }
}
