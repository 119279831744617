@import "./../required";

.icon-bars {
    position: relative;
    display: inline-block;
    width: 26px;
    vertical-align: middle;

    &,
    &::before,
    &::after {
        height: 3px;
        background-color: $navbar-light-color;
        border-radius: .2rem;
        transition: transform .15s linear;
    }

    &::before,
    &::after {
        position: absolute;
        right: 0;
        left: 0;
        content: " ";
    }

    &::before {
        transform: translateY(-8px) rotate(0);
    }

    &::after {
        transform: translateY(8px) rotate(0);
    }
}

.icon-bars-x {
    transform: rotate(45deg);

    &::before {
        transform: translateY(0) rotate(-90deg);
    }

    &::after {
        transform: translateY(0) rotate(-90deg);
    }
}
