@use "sass:map";

@import "./../required";

.navigation-menu-mobile {
    position: relative;
    z-index: 1;
    width: 100%;
    height: calc(100% - $mobile-menu-height);
    padding: $spacer;
    overflow: auto;
    background-color: $gray-100;

    .main-navigation {
        display: flex;
        flex-direction: column;
        gap: $spacer;

        .main-menu-item {
            display: block;
            width: 100%;
            padding: $spacer;
            color: $secondary;
            text-align: left;
            background-color: $white;
            border: none;
            border-radius: $border-radius;
            box-shadow: $box-shadow-sm;
        }

        @include list-unstyled();
    }

    .categories {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $spacer;
        justify-items: center;
    }

    .menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        aspect-ratio: 1 / 1;
        color: $secondary;
        background-color: $white;
        border-radius: $border-radius;
        box-shadow: $box-shadow-sm;

        &.back {
            flex-direction: row;
            border: none;
        }
    }

    .image {
        width: 100px;
        height: 100px;
        object-fit: cover;
        margin-bottom: map.get($spacers, 2);
    }

    .title {
        padding: 0 1rem;
        text-align: center;
    }

    .back-button {
        display: block;
        width: 100%;
        padding: $spacer;
        margin-bottom: $spacer;
        color: $secondary;
        text-align: left;
        background-color: $white;
        border: none;
        border-radius: $border-radius;
        box-shadow: $box-shadow-sm;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}
