@use "sass:map";


// Color system

$gray-100:                      #f8f9fa; // default
$gray-600:                      #6c757d; // default
$gray-900:                      #212529; // default
$black:                         #000; // default

$blue:                          #24aac5;
$purple:                        #af2bab;
$red:                           #c8002d;
$orange:                        #c16022;
$yellow:                        #daa819;
$green:                         #28a745;
$cyan:                          #0dcaf0; // default

$primary:                       $purple;
$secondary:                     $gray-900;
$info:                          $blue;


// Options

$enable-negative-margins:       true;


// Spacing

$spacer:                        1rem; // default
$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
); // default


// Links

$link-color:                    $primary; // default
$link-decoration:               none;


// Components

$border-radius-sm:              .1rem;


// Typography

$font-family-sans-serif:        "Ping", system-ui, -apple-system, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base:                .9375rem; // 15px
$font-size-sm:                  $font-size-base * .8666; // 13px
$font-size-lg:                  $font-size-base * 1.1333; // 17px

$font-weight-bold:              700; // default

$headings-margin-bottom:        $spacer * 2;
$headings-font-weight:          $font-weight-bold;

$small-font-size:               80%;

$hr-border-color:               rgba($black, .1);


// Buttons + Forms

$input-btn-font-size:           1rem;

$input-btn-focus-color-opacity: .5;
$input-btn-focus-color:         rgba($secondary, $input-btn-focus-color-opacity);


// Buttons

$btn-white-space:               nowrap;


// Forms

$input-focus-border-color:      tint-color($secondary, 25%);


// Navbar

$navbar-brand-margin-end:           .5rem;

$navbar-toggler-padding-x:          .5rem;
$navbar-toggler-focus-width:        0;

$navbar-light-color:                rgba($black, 1);
$navbar-light-toggler-border-color: transparent;


// Dropdowns

$dropdown-item-padding-x:       1.5rem;


// Toasts

$toast-font-size:               $font-size-base;


// Badges

$badge-padding-y:               calc(#{$spacer} / 3);
$badge-padding-x:               $badge-padding-y;


// Modals

$modal-inner-padding:           map.get($spacers, 4);

$modal-backdrop-opacity:        .7;


// Progress bars

$progress-height: 10px;


// List group

$list-group-border-color:           rgba($black, .125); // default

$list-group-hover-bg:               $gray-100; // default
$list-group-active-color:           $link-color;
$list-group-active-bg:              $list-group-hover-bg;
$list-group-active-border-color:    $list-group-border-color;

$list-group-action-hover-color:     $list-group-active-color;


// Carousel

$carousel-control-color:        $gray-900;
$carousel-transition-duration:  .2s;
