@use "sass:map";

@import "./../required";
@import "../../scss/05-components/icon-bars";

.the-header {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;

    .body-nav-drawer & {
        height: 100vh;

        .form-search {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            height: auto;
        }
    }

    .navbar {
        position: relative;
        z-index: 1;
        background-color: $white;
        border-bottom: 1px solid $border-color;

        .navbar-container {
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-up(lg) {
                padding-top: $spacer;
                padding-bottom: $spacer;
            }
        }
    }

    .navbar-main {
        display: flex;
        align-items: center;
        width: 100%;
        padding: map.get($spacers, 2) map.get($spacers, 2) map.get($spacers, 2) 1rem;
    }

    .navbar-toggler {
        background-color: transparent;
        border: none;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .form-search {

        @include media-breakpoint-up(lg) {
            width: 100%;
            margin-right: map.get($spacers, 4);
        }
    }

    .nav {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 $spacer;
    }

    @include media-breakpoint-down(lg) {
        .navbar-container {
            padding: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        .body-nav-drawer & {
            .form-search {
                display: block;
            }
        }
    }
}

.body-nav-drawer {
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        overflow: scroll;
    }
}

.the-navigation-menu {
    display: none;
    background-color: $white;
    border-bottom: 1px solid $border-color-translucent;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.sticky-footer {

    .nav {
        align-items: center;
        justify-content: space-around;
        height: $mobile-menu-height;
        background-color: $white;
        border-top: 1px solid $border-color;
    }
}

@include media-breakpoint-down(lg) {
    .nav {
        transition: $transition-base;
        transition-delay: .2s;
    }

    .is-scrolling-down .nav {
        transform: translateY(100%);
    }

    .search-open .navbar-toggler,
    .search-open .navbar-main .brand-logo {
        display: none;
    }

    .search-open .navbar-main {
        padding: map.get($spacers, 2);
    }

    .search-open .form-search {
        width: 100%;
    }
}

@include media-breakpoint-up(lg) {
    .the-navigation-menu {
        transition: $transition-base;
        transition-delay: .2s;
    }

    .is-scrolling-down .the-navigation-menu {
        transform: translateY(-100%);

        .dropdown-menu {
            opacity: 0;
        }
    }
}
